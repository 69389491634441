import PersonIcon from '@material-ui/icons/Person';
import WisdomList from './WisdomList'
import WisdomEdit from './WisdomEdit'
import WisdomCreate from './WisdomCreate'

export default {
  create: WisdomCreate,
  edit: WisdomEdit,
  list: WisdomList,
  icon: PersonIcon,
};