import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {
  BooleanInput,
  Datagrid,
  DateField,
  SimpleForm,
  SimpleFormIterator,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  DateInput,
  SelectInput,
  TextInput, useDataProvider, FormDataConsumer, ReferenceArrayInput, AutocompleteArrayInput, ArrayInput,
} from 'react-admin';

import {MediaInput} from 'src/components/inputs/MediaInput'
import {PeriodList, SignDescriptionValueTypeList, SignTypes} from 'src/types'
import {RichTextInput} from 'src/components/inputs/RichTextInput'

export const SignDescriptionForm = props => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <BooleanInput source={'published'} label={'Опубликовано'}/>
      <SelectInput source={'sign'} label={'Знак зодиака'}  helperText={'Слева'}  choices={SignTypes} variant={'outlined'} validate={required()} />
      <DateInput source={'startDate'} label={'Стартовая дата'} variant={'outlined'} validate={required()}/>
      <DateInput source={'endDate'} label={'Конечная дата'} variant={'outlined'} validate={required()}/>

      <ArrayInput source="translations" label={'Контент'}>
        <SimpleFormIterator>
          <SelectInput source="languageCode" label={'Язык'}  variant={'outlined'} choices={[{id: 'ru', name: 'RU'}, {id: 'en', name: 'EN'}]} validate={required()} />
          <ArrayInput source="valueBlocks" label={'Значения'}>
            <SimpleFormIterator>
              <SelectInput source="type" label={'Тип'}  variant={'outlined'} choices={SignDescriptionValueTypeList} validate={required()} />

              <TextInput source="title" label={'Заголовок'}  helperText={'Справа'} fullWidth={true} variant={'outlined'} validate={required()} />
              <TextInput source="body" label={'Описание'} multiline={true} fullWidth={true} variant={'outlined'} validate={required()} />
            </SimpleFormIterator>
          </ArrayInput>

          <ArrayInput source="traitsByPeriodOfBirthBlocks" label={'Характеристики'}>
            <SimpleFormIterator>
              <DateInput source={'startDate'} label={'Стартовая дата'} variant={'outlined'} validate={required()}/>
              <DateInput source={'endDate'} label={'Конечная дата'} variant={'outlined'} validate={required()}/>
              <TextInput source="title" label={'Заголовок'} helperText={'Заголовок харакристики. Выводится в начале текста отдельным цветом'} multiline={true} fullWidth={true} variant={'outlined'} validate={required()} />
              <TextInput source="body" label={'Описание'} multiline={true} fullWidth={true} variant={'outlined'} validate={required()} />
            </SimpleFormIterator>
          </ArrayInput>

          <TextInput source="temperBlock.title" label={'Характер Заголовок'}  multiline={true} fullWidth={true} variant={'outlined'} />
          <RichTextInput source="temperBlock.body" label={'Характер Описание'} multiline={true} fullWidth={true} variant={'outlined'} />

          <TextInput source="careerBlock.title" label={'Профессия Заголовок'}  multiline={true} fullWidth={true} variant={'outlined'} />
          <RichTextInput source="careerBlock.body" label={'Профессия Описание'} multiline={true} fullWidth={true} variant={'outlined'} />

          <TextInput source="loveBlock.title" label={'Любовь Заголовок'}  multiline={true} fullWidth={true} variant={'outlined'} />
          <RichTextInput source="loveBlock.body" label={'Любовь Описание'} multiline={true} fullWidth={true} variant={'outlined'} />

        </SimpleFormIterator>
      </ArrayInput>

    </SimpleForm>
  );
};
