import * as React from 'react';
import {FC, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {
  BooleanInput,
  Datagrid,
  Create,
  Show,
  TabbedShowLayout,
  SimpleForm,
  DateField,
  Tab,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  SelectInput,
  DeleteButton,
  TabbedForm,
  TextField,
  TextInput, SelectField,
} from 'react-admin';
import {GenderTypes, LangTypes, SignTypes} from 'src/types'
import {UserAchievementList} from 'src/resources/user/UserAchievementList'
import {UserProfileList} from 'src/resources/user/UserProfileList'
import {UserActionList} from 'src/resources/user/UserActionList'

interface TitleProps {
  record?: any;
}

const Title: FC<TitleProps> = ({record}) =>
 <span>Пользователь #{record.id} {record.defaultProfile?.name} {record.phone}</span>

const UserShow: FC<EditProps> = props => {
  return (<Show {...props} title={<Title/>}>
    <TabbedShowLayout>
      <Tab label="Информация">
        <TextField source={'phone'} label={'Телефон'}/>
        <TextField source={'defaultProfile.name'} label={'Имя'}/>
        <SelectField source={'defaultProfile.sign'} choices={SignTypes} label={'Знак зодиака'}/>
        <SelectField source={'defaultProfile.gender'} choices={GenderTypes} label={'Пол'}/>
        <DateField source="defaultProfile.birthday" label={'Дата рождения'} />
        <SelectField source={'language'} label={'Язык'} choices={LangTypes}/>
        <DateField source={'lastActivityAt'} label={'Был онлайн'} showTime={true}/>
        <DateField source="updatedAt" label={'Обновлено'} showTime={true} />
        <DateField source="createdAt" label={'Создано'}showTime={true} />
        <TextField source={'totalTaroOpened'} label={'Открыто карт таро'}/>
        <TextField source={'totalTaroPageVisits'} label={'Посещение экрана таро'}/>
        <TextField source={'totalPredictions'} label={'Всего предсказаний'}/>
        <TextField source={'totalPredictionsPageVisits'} label={'Посещение страницы предсказаний'}/>
        <TextField source={'totalHoroscopes'} label={'Кол-во гороскопов по API'}/>
        <TextField source={'totalHoroscopePageVisits'} label={'Посещение экрана гороскопы'}/>
        <TextField source={'totalNumbers'} label={'Вычислено чисел'}/>
        <TextField source={'totalBeautyCalendar'} label={'Кол-во календарей красоты по API'}/>
        <TextField source={'totalBeautyCalendarPageVisits'} label={'Посещение календарь красоты'}/>
        <TextField source={'totalAppStarted'} label={'Кол-во открытий приложения'}/>
        <TextField source={'daysOfUseInRow'} label={'Использовано дней подряд'}/>
        <TextField source={'maxDaysOfUseInRow'} label={'Использовано макс кол-во дней подряд'}/>
      </Tab>
      <Tab label="Профили" path="profiles">
        <UserProfileList />
      </Tab>
      <Tab label="Действия" path="user-actions">
        <UserActionList />
      </Tab>
      <Tab label="Достижения" path="achievements">
        <UserAchievementList />
      </Tab>

    </TabbedShowLayout>
  </Show>)
};

export default UserShow;