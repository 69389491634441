import * as React from 'react';
import {FC} from 'react';
import {
  Filter,
  List,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
  SelectInput,
  SelectField,
  FunctionField,
  ReferenceField,
  TextField,
  ListProps,
  Datagrid,
  BooleanInput, DateField, SimpleForm, Admin
} from 'react-admin';
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import TransTextField from 'src/components/fields/TransTextField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import {getTranslation} from 'src/utils/translation'
import EmptyList from 'src/components/list/EmptyList'
import VisibilityField from 'src/components/fields/VisibilityField'
import {DateInput, TimeInput} from 'src/components/inputs/DateInput'
import {format} from 'date-fns'

import {makeStyles} from '@material-ui/core/styles';
import classnames from 'classnames';
import {
  PushNotificationChannel,
  PushPriorities,
  PushSoundTypes,
  PushStatuses,
  PushTargetTypes,
  PushTypes
} from 'src/types'
import {timeToDate} from 'src/utils/dateUtil'

import Chip, {ChipProps} from '@material-ui/core/Chip';
import PushNameField from 'src/resources/push/components/PushNameField'

const useStyles = makeStyles(
  {
    chip: {margin: 4, cursor: 'inherit'},
  },
  {name: 'RaChipField'}
);
const ChannelsField = (props) => {
  const classes = useStyles(props);
  return (

    <div>
      {props.record.channels.filter(item => PushNotificationChannel.find(i => i.id === item)).map(item => (
        <Chip
          className={classnames(classes.chip)}
          label={PushNotificationChannel.find(i => i.id === item)?.name}
        />

      ))}
    </div>
  )
}


const exporter = posts => {
  return csvExporter({
    columns: {
      'id': 'ID',
      'type': 'Тип',
      'targetType': 'Кому отправить',
      'title': 'Заголовок',
      'body': 'Тело',
      'channels': 'Каналы',
      'statusName': 'Статус',
      'typeName': 'Кому',
      'sound': 'Звук',
      'priority': 'Приоритет',
      'scheduleSentAt': 'Отправить в',
      'createdAt': 'Создано',
      'admin': 'Админ'
    },
    data: posts.map(item => ({
      ...item,
      title: getTranslation(item)?.title,
      body: getTranslation(item)?.body,
      channels: item.channels.filter(item => PushNotificationChannel.find(i => i.id === item)).map(item => PushNotificationChannel.find(i => i.id === item)?.name).join(', '),
      admin: item.admin?.name || '',
      type: PushTypes.find(i => i.id === item.type)?.name || '',
      targetType: PushTargetTypes.find(i => i.id === item.targetType)?.name || '',
      sound: PushSoundTypes.find(i => i.id === item.sound)?.name || '',
      priority: PushPriorities.find(i => i.id === item.priority)?.name || '',
      createdAt: formatCsvDate(item.createdAt),
      scheduleSentAt: formatCsvDate(item.createdAt),
      updatedAt: formatCsvDate(item.updatedAt)
    })),
    fileName: 'push'
  })
};

const _Filter = (props) => (
  <Filter {...props}>

    <TextInput source="translations.title||$contL" label={'Поиск'} alwaysOn={true}
               resettable={true} variant={'outlined'}/>


    <SelectInput
      source="type"
      label={'Тип'}
      variant={'outlined'} fullWidth={true}
      choices={PushTypes}
    />
    <SelectInput
      source="targetType"
      label={'Кому'}
      variant={'outlined'} fullWidth={true} alwaysOn={true}
      choices={PushTargetTypes}
    />
    {props.permissions === 'admin' && <ReferenceInput
        allowEmpty={false}
        label="Пользователь" source="userId" reference="user" variant={'outlined'}
        filterToQuery={searchText => ({...(searchText ? {'id||$eq': searchText} : {})})}
        sort={{field: 'id', order: 'ASC'}}
        fullWidth={true} perPage={100}>
        <AutocompleteInput optionText={(i) => `#${i.id} ${i.defaultProfile?.name || ''}`}/>
    </ReferenceInput>}


  </Filter>
)

const PushList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      exporter={exporter}
      bulkActionButtons={false}
      filters={<_Filter/>}
      sort={{field: 'id', order: 'DESC'}}
      title="Push уведомления"
      empty={<EmptyList title={'Нет push уведомления'} description={'Вы можете добавить push уведомление'}
                        buttonText={'Добавить push уведомление'}/>}
    >
      <Datagrid rowClick={'edit'}>
        <IdShowButtonField source="id" label={'ID'}/>
        <PushNameField source="name" label={'Push'}/>
        <SelectField source="type" choices={PushTypes} label={'Тип'}/>
        <SelectField source="targetType" choices={PushTargetTypes} label={'Кому'}/>
        <ChannelsField label={'Каналы'}/>
        <DateField source={'scheduleSentAt'} label={'Отправить в'} showTime={true}/>
        <DateField source={'createdAt'} label={'Создано'} showTime={true}/>
        {props.permissions === 'admin' && <ReferenceField label="Пользователь" source="userId" reference="user">
            <FunctionField render={(i) => `#${i.id} ${i.defaultProfile?.name || ''}`}/>
        </ReferenceField>}


      </Datagrid>
    </List>
  );
}

export default PushList;
