import PersonIcon from '@material-ui/icons/Person';
import CompatibilityInstructionList from './CompatibilityInstructionList'
import CompatibilityInstructionEdit from './CompatibilityInstructionEdit'
import CompatibilityInstructionCreate from './CompatibilityInstructionCreate'

export default {
  create: CompatibilityInstructionCreate,
  edit: CompatibilityInstructionEdit,
  list: CompatibilityInstructionList,
  icon: PersonIcon,
};