import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {
  BooleanInput,
  Datagrid,
  DateField,
  SimpleForm,
  SimpleFormIterator,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  SelectInput,
  TextInput, useDataProvider, FormDataConsumer, ReferenceArrayInput, AutocompleteArrayInput, ArrayInput, DateInput,
} from 'react-admin';

import {MediaInput} from 'src/components/inputs/MediaInput'
import {RichTextInput} from 'src/components/inputs/RichTextInput'


export const PageForm = props => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <BooleanInput source={'published'} label={'Опубликовано'}/>
      <TextInput source={'key'} label={'Ключ'} validate={required()}  variant={'outlined'} />
      <ArrayInput source="translations" label={'Контент'}>
        <SimpleFormIterator>
          <SelectInput source="languageCode" label={'Язык'}  variant={'outlined'} choices={[{id: 'ru', name: 'RU'}, {id: 'en', name: 'EN'}]} validate={required()} />
          <TextInput source={'title'} label={'Название'} validate={required()}  fullWidth={true} variant={'outlined'}/>
          <RichTextInput source="body" label={'Содержимое'} multiline={true} fullWidth={true} variant={'outlined'} validate={required()} />
        </SimpleFormIterator>
      </ArrayInput>



    </SimpleForm>
  );
};
