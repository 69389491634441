import PersonIcon from '@material-ui/icons/Person';
import SymbolOfDayList from './SymbolOfDayList'
import SymbolOfDayEdit from './SymbolOfDayEdit'
import SymbolOfDayCreate from './SymbolOfDayCreate'

export default {
  create: SymbolOfDayCreate,
  edit: SymbolOfDayEdit,
  list: SymbolOfDayList,
  icon: PersonIcon,
};