import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {
  BooleanInput,
  Datagrid,
  DateField,
  SimpleForm,
  SimpleFormIterator,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  SelectInput,
  TextInput, useDataProvider, FormDataConsumer, ReferenceArrayInput, AutocompleteArrayInput, ArrayInput, NumberInput,
} from 'react-admin';
import {PredictionTypeList} from 'src/types'

export const PredictionForm = props => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <BooleanInput source={'published'} label={'Опубликовано'}/>
      <SelectInput source={'type'} choices={PredictionTypeList} label={'Тип'} validate={required()}   variant={'outlined'} fullWidth={true}/>
      <NumberInput source={'weight'} label={'Вес'} validate={required()}   variant={'outlined'} fullWidth={true}/>
      <ArrayInput source="translations" label={'Контент'}>
        <SimpleFormIterator>
          <SelectInput source="languageCode" label={'Язык'}  variant={'outlined'} choices={[{id: 'ru', name: 'RU'}, {id: 'en', name: 'EN'}]} validate={required()} />
          <TextInput source="answer" label={'Ответ'} multiline={true} fullWidth={true} variant={'outlined'} validate={required()} />
        </SimpleFormIterator>
      </ArrayInput>

    </SimpleForm>
  );
};
