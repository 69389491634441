import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import {Link} from 'react-router-dom';
import {styled} from '@material-ui/core/styles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Badge from './Badge';
import Typography from '@material-ui/core/Typography';
import {useTranslate} from 'react-admin';
import {format} from 'date-fns'
import {useState} from 'react'
import {getWeekEnd, getWeekStart} from 'src/utils/dateRangeGenerator'

import {ru} from 'date-fns/locale'

interface GroupedWithSignDataItem {
  sign: { id: string, name: string },
  data: any,
  exists: boolean
  existsAll: boolean
}

interface GroupedWithSignData {
  date: string
  items: GroupedWithSignDataItem[]
}

interface Props {
  data?: GroupedWithSignData[];
  title?: string,
  rangeType: 'day' | 'week' | 'month',
  resource?: string
}

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
  },
  cost: {
    marginRight: '1em',
    color: theme.palette.text.primary,
  }
}));

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({theme}) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ExpandMoreIcon/>}
    {...props}
  />
))(({theme}) => ({
  backgroundColor: 'white',

}));

const AccordionDetails = styled(MuiAccordionDetails)(({theme}) => ({
  padding: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, .125)',
  display: 'block'
}));

const GroupHeader = ({record, rangeType}: { record: GroupedWithSignData, rangeType: 'day' | 'week' | 'month' }) => {
  const totalNotExists = record.items.filter(i => !i.exists && !i.existsAll).length;
  const totalExists = record.items.filter(i => i.exists).length;
  const totalAll = record.items.filter(i => !i.exists && i.existsAll).length;
  const total = record.items.length

  const getTitle = () => {

    const dayFormat = 'dd.MM.yyyy';
    switch (rangeType) {
      case 'day':
        return format(new Date(record.date), dayFormat, {locale: ru})
      case 'week':
        return `${format(getWeekStart(record.date), dayFormat)} - ${format(getWeekEnd(record.date), dayFormat)}`;
      case 'month':
        return format(new Date(record.date), 'MMMM yyyy', {locale: ru})
    }
  }
  return <div>
    <Typography>{getTitle()}
      {totalNotExists > 0 && <Badge badgeContent={totalNotExists} color={'error'}/>}
      {totalAll > 0 && <Badge badgeContent={totalAll} color={'warning'}/>}
      {totalExists > 0 && <Badge badgeContent={`${totalExists}`} color={'success'}/>}
    </Typography>
  </div>
}
const SignItem = ({record, date, resource, rangeType}: {
  record: GroupedWithSignDataItem, resource: string, date: string
  rangeType: 'day' | 'week' | 'month',
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const getTextColor = () => {
    if (record.exists) {
      return theme.palette.success.main
    } else if (record.existsAll) {
      return theme.palette.warning.main
    } else {
      return theme.palette.error.main
    }

  }
  const getRangeCreateParam = () => {
    switch (rangeType) {
      case 'day':
        return 'daily'
      case 'week':
        return 'weekly'
      case 'month':
        return 'monthly'
    }
  }
  const getText = () => {
    if (record.exists) {
      return 'Есть'
    } else if (record.existsAll) {
      return 'Общий'
    } else {
      return 'Нет'
    }
  }

  return <ListItem
    key={record.sign?.id}
    component={Link}
    button
    to={
      {
        pathname: record.exists && record.data?.id ? `/${resource}/${record.data?.id}` : `/${resource}/create`,
        state: {
          record: {
            startDate: format(new Date(date), 'dd.MM.yyyy'),
            sign: record.sign.id,
            type: getRangeCreateParam(),
            published: true
          },
          redirect: '/'
        }
      }
    }
  >
    <ListItemText
      primary={record.sign.name}
      secondary={''}
    />
    <ListItemSecondaryAction>
                            <span className={classes.cost} style={{color: getTextColor()}}>
                              {getText()}
                            </span>
    </ListItemSecondaryAction>
  </ListItem>
}
const GroupedWithSign = (props: Props) => {
  const {data = [], title, rangeType, resource} = props;
  const classes = useStyles();
  const translate = useTranslate();
  const [currentExpanded, setCurrentExpanded] = useState();
  const handleToggleAccordion = (key) => {
    if (key === currentExpanded) {
      setCurrentExpanded(null)
    } else {
      setCurrentExpanded(key)
    }

  }
  return (
    <Card className={classes.root}>
      <CardHeader title={title}/>
      <List dense={true}>
        {data.map(record => (
          <Accordion expanded={currentExpanded === record.date} onChange={() => handleToggleAccordion(record.date)}>
            <AccordionSummary aria-controls={`content-${record.date}`}>
              <GroupHeader rangeType={rangeType} record={record}/>
            </AccordionSummary>

            <AccordionDetails>
              <List dense={true}>
                {record.items.map(item => (
                  <SignItem record={item} date={record.date} resource={resource} rangeType={rangeType}/>
                ))}
              </List>
            </AccordionDetails>
          </Accordion>
        ))}
      </List>
    </Card>
  );
};

export default GroupedWithSign;