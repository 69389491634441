import PersonIcon from '@material-ui/icons/Person';
import SignDescriptionList from 'src/resources/sign-description/SignDescriptionList'
import SignDescriptionEdit from 'src/resources/sign-description/SignDescriptionEdit'
import SignDescriptionCreate from 'src/resources/sign-description/SignDescriptionCreate'

export default {
  create: SignDescriptionCreate,
  edit: SignDescriptionEdit,
  list: SignDescriptionList,
  icon: PersonIcon,
};