import * as React from 'react';
import {FC} from 'react';
import {
  Filter,
  List,
  TextInput,
  ListProps,
  SelectField,
  Datagrid,
  BooleanInput, DateField, SimpleForm, SelectInput
} from 'react-admin';
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import TransTextField from 'src/components/fields/TransTextField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import {getTranslation} from 'src/utils/translation'
import EmptyList from 'src/components/list/EmptyList'
import VisibilityField from 'src/components/fields/VisibilityField'
import {DateInput} from 'src/components/inputs/DateInput'
import {AchievementTypes, SignTypes} from 'src/types'
import AssetField from 'src/components/fields/AssetField'


const exporter = posts => {
  return csvExporter({
    columns: {
      'id': 'ID',
      'type': 'Тип',
      'title': 'Название',
      'body': 'Описание',
      'published': 'Опубликовано',
      'createdAt': 'Создано',

    },
    data:  posts.map(item => ({
      ...item,
      type: AchievementTypes.find(i => i.id === item.type)?.name || '',
      title: getTranslation(item)?.title,
      body: getTranslation(item)?.body,
      published: item.published ? 'Да' : '',
      createdAt:  formatCsvDate(item.createdAt),
      updatedAt:  formatCsvDate(item.updatedAt)
    })),
    fileName: 'Achievement'
  })
};

const _Filter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'} />
    <AssetField source={'asset'} label={'Картинка'}/>
    <TextInput source="translations.body||$contL" label={'Поиск'}  variant={'outlined'}  alwaysOn={true} resettable={true}/>
    <BooleanInput source={'published'} label={'Опубликовано'}/>
    <SelectInput source={'type'} label={'Тип'} choices={AchievementTypes} variant={'outlined'}/>

  </Filter>
)

const AchievementList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      exporter={exporter}
      bulkActionButtons={false}
      filters={<_Filter/>}
      title="Аффирмации"
      empty={<EmptyList title={'Нет достижений'} description={'Вы можете добавить достижение'} buttonText={'Добавить достижение'}/>}
    >
    <Datagrid rowClick={'edit'}>
      <IdShowButtonField source={'id'} label={'ID'}/>
      <VisibilityField source={'published'} label={'Опубликовано'}/>
      <AssetField source={'asset'} label={'Картинка'}/>
      <SelectField source={'type'} label={'Тип'} choices={AchievementTypes}/>
      <TransTextField source="title" label={'Название'}/>
      <TransTextField source="body" label={'Описание'}/>
      <DateField source="createdAt" label={'Создано'}showTime={true} />
      <DateField source="updatedAt" label={'Обновлено'} showTime={true} />
    </Datagrid>
    </List>
  );
}

export default AchievementList;
