import PersonIcon from '@material-ui/icons/Person';
import TaroList from './TaroList'
import TaroEdit from './TaroEdit'
import TaroCreate from './TaroCreate'

export default {
  create: TaroCreate,
  edit: TaroEdit,
  list: TaroList,
  icon: PersonIcon,
};