import * as React from 'react';
import {FC} from 'react';
import {
  Filter,
  List,
  TextInput,
  ListProps,
  Datagrid,
  BooleanInput, DateField, SelectField, SelectInput, DateInput, SimpleForm
} from 'react-admin';
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import TransTextField from 'src/components/fields/TransTextField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import {getTranslation} from 'src/utils/translation'
import EmptyList from 'src/components/list/EmptyList'
import {PeriodList, SignTypes} from 'src/types'
import VisibilityField from 'src/components/fields/VisibilityField'


const exporter = posts => {
  return csvExporter({
    columns: {
      'id': 'ID',
      'sign': 'Знак зодиака',
      'type': 'Период',
      'startDate': 'Стартовая дата',
      'title': 'Название',
      'published': 'Опубликовано',
      'createdAt': 'Создано',

    },
    data:  posts.map(item => ({
      ...item,
      type: PeriodList.find(i => i.id === item.type)?.name || '',
      sign: SignTypes.find(i => i.id === item.sign)?.name || '',
      startDate:  formatCsvDate(item.startDate, true),
      title: getTranslation(item)?.title,
      published: item.published ? 'Да' : '',
      createdAt:  formatCsvDate(item.createdAt),
      updatedAt:  formatCsvDate(item.updatedAt),
      publishedAt:  formatCsvDate(item.publishedAt),
    })),
    fileName: 'Affirmation'
  })
};

const _Filter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'} />
    <TextInput source="translations.title||$contL" label={'Поиск'}  variant={'outlined'}  alwaysOn={true} resettable={true}/>
    <SelectInput source={'sign'} label={'Знак зодиака'} choices={SignTypes} variant={'outlined'} allowEmpty={true} emptyText={'Общий для всех'} resettable={true} />
    <BooleanInput source={'published'} label={'Опубликовано'}/>

    <SelectInput source={'type'} label={'Период'} choices={PeriodList} variant={'outlined'}  />
    <DateInput source={'startDate'} label={'Стартовая дата'}/>
  </Filter>
)

const BeautyCalendarList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      exporter={exporter}
      bulkActionButtons={false}
      filters={<_Filter/>}
      title="Календарь красоты"
      empty={<EmptyList title={'Нет календарь красоты'} description={'Вы можете добавить календарь красоты'} buttonText={'Добавить календарь красоты'}/>}
    >
    <Datagrid rowClick={'edit'}>
      <IdShowButtonField source={'id'} label={'ID'}/>
      <VisibilityField source={'published'} label={'Опубликовано'}/>
      <SelectField source={'sign'} label={'Знак зодиака'} choices={SignTypes} emptyText={'Общий для всех'}/>
      <SelectField source={'type'} label={'Период'} choices={PeriodList}/>
      <DateField source="startDate" label={'Стартовая дата'} />
      <TransTextField source="title" label={'Название'}/>
      <DateField source="createdAt" label={'Создано'} showTime={true} />
      <DateField source="updatedAt" label={'Обновлено'} showTime={true} />
    </Datagrid>
    </List>
  );
}

export default BeautyCalendarList;
