import * as React from 'react';
import {FC} from 'react';
import {
  Filter,
  List,
  TextInput,
  ListProps,
  SelectField,
  SelectInput,
  FunctionField,
  Datagrid,
  BooleanInput, DateField, SimpleForm, ReferenceInput, AutocompleteInput, ReferenceField, TextField
} from 'react-admin';
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import TransTextField from 'src/components/fields/TransTextField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import {getTranslation} from 'src/utils/translation'
import EmptyList from 'src/components/list/EmptyList'
import VisibilityField from 'src/components/fields/VisibilityField'
import {DateInput} from 'src/components/inputs/DateInput'
import {UserActionTypes} from 'src/types'


const exporter = posts => {
  return csvExporter({
    columns: {
      'id': 'ID',
      'action': 'Действие',
      'user': 'Пользователь',
      'createdAt': 'Создано',

    },
    data:  posts.map(item => ({
      ...item,
      action: UserActionTypes.find(i => i.id === item.action)?.name || '',
      user: item.user?.id ? `#${item.user?.id }` : ``,
      createdAt:  formatCsvDate(item.createdAt),
    })),
    fileName: 'UserAction'
  })
};

const _Filter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'} />
    <SelectInput source="action" label={'Действие'}  choices={UserActionTypes} variant={'outlined'}  alwaysOn={true} resettable={true}/>
    <ReferenceInput
      allowEmpty={false}
      label="Пользователь" source="userId" reference="user" variant={'outlined'}
      filterToQuery={searchText => ({...(searchText ? {'id||$eq': searchText} : {})})}
      sort={{field: 'id', order: 'ASC'}}
      fullWidth={true} perPage={100}>
      <AutocompleteInput optionText={(i) =>  `#${i.id} ${i.defaultProfile?.name || ''}`}/>
    </ReferenceInput>
    <DateInput source={'date'} label={'Дата'}/>

  </Filter>
)

const UserActionList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      exporter={exporter}
      bulkActionButtons={false}
      filters={<_Filter/>}
      title="Действия пользователей"
      empty={<EmptyList title={'Нет действий пользователей'} showCreate={false}/>}
    >
    <Datagrid rowClick={'edit'}>
      <IdShowButtonField source={'id'} label={'ID'}/>
      <SelectField source={'action'} label={'Действие'} choices={UserActionTypes}/>
      <ReferenceField label="Пользователь" source="userId" reference="user">
        <FunctionField source="id" render={i => `#${i.id} ${i.defaultProfile?.name || ''}`}/>
      </ReferenceField>
      <DateField source="createdAt" label={'Создано'}showTime={true} />
    </Datagrid>
    </List>
  );
}

export default UserActionList;
