import PersonIcon from '@material-ui/icons/Person';
import AvatarList from 'src/resources/avatar/AvatarList'
import AvatarEdit from 'src/resources/avatar/AvatarEdit'
import AvatarCreate from 'src/resources/avatar/AvatarCreate'

export default {
  create: AvatarCreate,
  edit: AvatarEdit,
  list: AvatarList,
  icon: PersonIcon,
};