import * as React from 'react';
import {
  BooleanInput,
  SimpleForm
} from 'react-admin';

export const SettingsForm = props => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <BooleanInput source={'oneTaroADay'} label={'Режим одна карта таро в день'}/>
    </SimpleForm>
  );
};
