import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {
  BooleanInput,
  Datagrid,
  DateField,
  SimpleForm,
  SimpleFormIterator,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  SelectInput,
  TextInput,
  useDataProvider,
  FormDataConsumer,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ArrayInput,
  DateInput,
  ReferenceInput, AutocompleteInput,
} from 'react-admin';

import {MediaInput} from 'src/components/inputs/MediaInput'
import {PeriodList, SignTypes} from 'src/types'
import {getTranslation} from 'src/utils/translation'
import {RichTextInput} from 'src/components/inputs/RichTextInput'

export const BeautyCalendarForm = props => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <BooleanInput source={'published'} label={'Опубликовано'}/>
      <SelectInput source={'type'} label={'Период'} choices={[
        {id: 'daily', name: 'Дневной'},
        {id: 'monthly', name: 'Месячный'}
      ]} variant={'outlined'} validate={required()} />
      <SelectInput source={'sign'} label={'Знак гороскопа'} helperText={'Если не указать значение то будет использоваться для всех знаков зоидаков'} allowEmpty={true} fullWidth={true} emptyText={'Общий для всех'} choices={SignTypes} variant={'outlined'} />
      <DateInput source={'startDate'} label={'Стартовая дата'} variant={'outlined'} validate={required()}/>


      <ArrayInput source="translations" label={'Контент'}>
        <SimpleFormIterator>
          <SelectInput source="languageCode" label={'Язык'}  variant={'outlined'} choices={[{id: 'ru', name: 'RU'}, {id: 'en', name: 'EN'}]} validate={required()} />
          <TextInput source="title" label={'Название'} fullWidth={true} variant={'outlined'} validate={required()} />
        </SimpleFormIterator>
      </ArrayInput>

      <ArrayInput source="blocks" label={'Текст блоки'}>
        <SimpleFormIterator>
          <MediaInput source={'asset'} label={'Картинка'} />

          <ArrayInput source="translations" label={'Контент'}>
            <SimpleFormIterator>
              <SelectInput source="languageCode" label={'Язык'}  variant={'outlined'} choices={[{id: 'ru', name: 'RU'}, {id: 'en', name: 'EN'}]} validate={required()} />
              <TextInput source="title" label={'Название'} fullWidth={true} variant={'outlined'} validate={required()} />
              <RichTextInput source="body" label={'Описание'} multiline={true} fullWidth={true} variant={'outlined'}  validate={required()} />
            </SimpleFormIterator>
          </ArrayInput>

          </SimpleFormIterator>
      </ArrayInput>

    </SimpleForm>
  );
};
