import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {
  BooleanInput,
  Datagrid,
  DateField,
  SimpleForm,
  SimpleFormIterator,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  CheckboxGroupInput,
  SelectInput,
  TextInput, useDataProvider, FormDataConsumer, ReferenceArrayInput, AutocompleteArrayInput, ArrayInput, DateInput,
} from 'react-admin';
import TimeInput from "src/components/inputs/TimeInput";
import {MediaInput} from 'src/components/inputs/MediaInput'
import {RichTextInput} from 'src/components/inputs/RichTextInput'
import {
  PushNotificationChannel,
  PushPriorities,
  PushSoundTypes,
  PushStatuses,
  PushTargetTypes,
  PushTypes
} from 'src/types'
import {timeToDate} from 'src/utils/dateUtil'
import {format} from 'date-fns'
import DateTimeInput from 'src/components/inputs/DateTimeInput'

const _Form = (props) => {
  const {record} = props;
  return (<>


    <SelectInput
      validate={required()}
      source="type"
      label={'Тип'}
      variant={'outlined'} fullWidth={true}
      choices={[
        { id: 'admin', name: 'Общее уведомление' },
        { id: 'autoRemind', name: 'Авто уведомление' },
      ]}
    />
    <FormDataConsumer fullWidth={true} variant={'outlined'}>
      {({ formData, ...rest }) => formData.type === 'autoRemind'  && (
        <TimeInput
          label="Время отправки"
          helperText={'Время отправки ежедневно'}
          source="autoSendAt"
          variant={'outlined'}
          fullWidth={true}
          validate={required()}
        />
      )}
    </FormDataConsumer>



    <SelectInput
      validate={required()}
      source="targetType"
      label={'Кому отправить'}
      variant={'outlined'} fullWidth={true}
      choices={[
        { id: 'all', name: 'Всем' },
      ]}
    />

    <CheckboxGroupInput source="channels" label={'Каналы доставки'} choices={PushNotificationChannel} />


    <ArrayInput source="translations" label={'Контент'}>
      <SimpleFormIterator>
        <SelectInput source="languageCode" label={'Язык'}  variant={'outlined'} choices={[{id: 'ru', name: 'RU'}, {id: 'en', name: 'EN'}]} validate={required()} />
        <TextInput source="title" label={'Название уведомления (Необязательно)'}
                   helperText={'Заголовок уведомления отображается для конечных пользователей.'} variant={'outlined'}
                   fullWidth={true} />
        <TextInput source="body" label={'Текст уведомления'} helperText={'Отображется под название уведомления'}
                   variant={'outlined'} fullWidth={true} validate={required()}/>
      </SimpleFormIterator>
    </ArrayInput>


    <TextInput source="icon" label={'Изображение в уведомлении (Необязательно)'}
               helperText={'Вставьте общедоступную ссылку на изображение.'} variant={'outlined'} fullWidth={true}/>
    <TextInput source="color" label={'Цвет иконки уведомления (Необязательно)'} helperText={'Только для Android'} variant={'outlined'}
               fullWidth={true}/>

    <SelectInput
      source="sound"
      initialValue={'default'}
      label={'Звук'}
      variant={'outlined'} fullWidth={true}
      choices={PushSoundTypes}
    />
    <TextInput source="tag" label={'Тэг (название группы уведмоления) (Необязательно)'}
               helperText={'Если есть активные уведомления с таким тэгом то они будут скрыты в центре уведомления. Только для Android'}
               variant={'outlined'} fullWidth={true}/>
    <SelectInput
      source="priority"
      label={'Приоритет'}
      initialValue={'high'}
      validate={required()}
      variant={'outlined'} fullWidth={true}
      helperText={'By default, notification messages are sent with high priority, and data messages are sent with normal priority. Normal priority optimizes the client app\'s battery consumption and should be used unless immediate delivery is required. For messages with normal priority, the app may receive the message with unspecified delay.\n' +
      '\n' +
      'When a message is sent with high priority, it is sent immediately, and the app can wake a sleeping device and open a network connection to your server.'}
      choices={PushPriorities}
    />

    <DateTimeInput
      label="Отправить в"
      helperText={'Отложенная отправка в определенное время'}
      source="scheduleSentAt"
      variant={'outlined'}
      fullWidth={true}
    />
  </>)
}
export const PushForm = props => {
  return (
    <SimpleForm {...props} redirect={'list'} initialValues={{type: 'admin', targetType: 'all'}}>
      <_Form/>
    </SimpleForm>
  );
};
