import * as React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemText from '@material-ui/core/ListItemText';
import Avatar from '@material-ui/core/Avatar';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import { Link } from 'react-router-dom';
import { useTranslate } from 'react-admin';
import {format} from 'date-fns'


interface Props {
  data?: any;
  title?: string
  resource?: string
}

const useStyles = makeStyles(theme => ({
  root: {
    flex: 1,
  },
  cost: {
    marginRight: '1em',
    color: theme.palette.text.primary,
  },
}));

const DateItem = ({record, date, resource}: { record: any, date: string, resource: string }) => {
  const classes = useStyles();
  const theme = useTheme();
  const getTextColor = () => {
    if (record.id) {
      return theme.palette.success.main
    } else{
      return theme.palette.error.main
    }

  }
  const getText = () => {
    return record.id ? 'Есть' : 'Нет'
  }
return(
  <ListItem
    key={record.id}
    button
    component={Link}
    to={
      {
        pathname: record?.id ? `/${resource}/${record.data?.id}` : `/${resource}/create`,
        state: {
          record: {
            date: format(new Date(date), 'MM.dd.yyyy'),
            published: true
          }
        }
      }
    }
    //    to={`/commands/${record.id}`}
  >

    <ListItemText
      primary={format(new Date(record.date), 'dd.MM.yyyy')}
      secondary={record.text}
    />
    <ListItemSecondaryAction>
                            <span className={classes.cost}  style={{color: getTextColor()}}>
                              {getText()}
                            </span>
    </ListItemSecondaryAction>
  </ListItem>);

}
const SimpleDates = (props: Props) => {
  const { data = [], title, resource  } = props;
  const classes = useStyles();
  const translate = useTranslate();
  return (
    <Card className={classes.root}>
      <CardHeader title={title} />
      <List dense={true}>
        {data.map(record => (
        <DateItem record={record} resource={resource} date={record.date}/>

        ))}
      </List>
    </Card>
  );
};

export default SimpleDates;