import * as React from 'react';
import {FC} from 'react';
import {
  Filter,
  List,
  TextInput,
  ListProps,
  Datagrid,
  FunctionField,
  BooleanInput, DateField, SimpleForm
} from 'react-admin';
import EmptyList from 'src/components/list/EmptyList'


const SettingsList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      exporter={false}
      hasCreate={false}
      bulkActionButtons={false}
      title="Настройки"
      empty={<EmptyList title={'Нет настройки'} description={'Вы можете добавить настройку'} buttonText={'Добавить настройки'}/>}
    >
    <Datagrid rowClick={'edit'}>
      <FunctionField source={'oneTaroADay'} render={(record) => record.oneTaroADay ? `Вкл.` : 'Выкл.'} label={'Режим 1 таро в день'}/>
      <DateField source="updatedAt" label={'Обновлено'} showTime={true} />
    </Datagrid>
    </List>
  );
}

export default SettingsList;
