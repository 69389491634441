import React, {useState, useEffect, useCallback, CSSProperties} from 'react';
import {useVersion, useDataProvider} from 'react-admin';
import {useMediaQuery, Theme} from '@material-ui/core';
import {addDays, addMonths, isSameDay, subDays} from 'date-fns';
import InputLabel from '@material-ui/core/InputLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import SimpleDates from 'src/resources/dashboard/SimpleDates';
import {addMissingDates, addMissingMonths, addMissingWeeks, getWeekStart} from 'src/utils/dateRangeGenerator'
import {
  getMonthStart,
  getNextMonthStart,
} from '@wojtekmaj/date-utils';
import {SignTypes} from 'src/types'
import GroupedWithSign from 'src/resources/dashboard/GroupedWithSign'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

const KEY_DAYS_COUNT = 'dashboard-days-count'
const KEY_WEEKS_COUNT = 'dashboard-weeks-count'
const KEY_MONTHS_COUNT = 'dashboard-months-count'

interface Stats {

}


interface State {
  symbolsOfDays?: [];

}

const styles = {
  flex: {display: 'flex'},
  flexColumn: {display: 'flex', flexDirection: 'column'},
  leftCol: {flex: 1, marginRight: '0.5em'},
  rightCol: {flex: 1, marginLeft: '0.5em'},
  singleCol: {marginTop: '1em', marginBottom: '1em'},
};

function getDateZeroTime(dateString) {
  const date = new Date(dateString);
  date.setHours(0, 0, 0);   // Set hours, minutes and seconds
  return date.toISOString();
}

/*
Получили данные сгруппировать их по дням
 */
const SelectBox = ({
                     value,
                     onChange,
                     label,
                     helperText,
                     options
                   }: { value?: any, onChange: (v) => void, label: string, helperText?: string, options: any[] }) => {
  console.log("SelectBoxOption", options);
  return (<div style={{minWidth: '150px', display: 'grid'}}><FormControl variant={'outlined'}>
    <InputLabel>{label}</InputLabel>
    <Select
      labelId="demo-simple-select-helper-label"
      id="demo-simple-select-helper"
      value={value}
      label={label}
      onChange={(e) => {
        console.log("e.currentTarget.value", e.target.value)
        onChange(e.target.value)
      }}
    >{options.map(option => <MenuItem value={option.value}>{option.name}</MenuItem>)}
    </Select>
    <FormHelperText>{helperText}</FormHelperText>
  </FormControl></div>)
}
const groupByDatesWishSign = (data) => {
  const result = [];
  let lastDate;
  for (const item of data) {
    if (!lastDate) {
      lastDate = item.startDate;
      result.push({date: item.startDate, items: []});
    }
    if (!isSameDay(new Date(lastDate), new Date(item.startDate))) {
      result.push({date: item.startDate, items: []});
    }
    result[result.length - 1].items.push(item);


    lastDate = item.startDate;

  }

  return result;
}
const generateSignForDates = (data) => {
  const result = [];
  for (const date of data) {
    const newItems = [];
    const hasAll = date.items.find(i => !i.sign)
    for (const sign of SignTypes) {
      const find = date.items.find(i => i.sign === sign.id);
      newItems.push({sign, exists: !!find, existsAll: !!hasAll, data: find || hasAll})

    }
    date.items = newItems
    result.push(date);
  }
  return result;
}
const Spacer = () => <span style={{width: '1em'}}/>;
const VerticalSpacer = () => <span style={{height: '1em'}}/>;
const getLocalStorage = (key, defaultVal): number => {
  return localStorage.getItem(key) || defaultVal;
}
const setLocalStorage = (key, val) => {
  return localStorage.setItem(key, val);
}
const Dashboard = () => {
  const startDate = new Date();
  const [symbolsOfDays, setSymbolsOfDays] = useState<any[]>([]);
  const [horoscopeDaily, setHoroscopeDaily] = useState<any[]>([]);
  const [horoscopeWeekly, setHoroscopeWeekly] = useState<any[]>([]);
  const [affirmation, setAffirmation] = useState<any[]>([]);
  const [wisdom, setWisdom] = useState<any[]>([]);
  const [daysCount, setDaysCount] = useState(getLocalStorage(KEY_DAYS_COUNT, 7));

  const [monthsCount, setMonthsCount] = useState(getLocalStorage(KEY_MONTHS_COUNT, 12));
  const [weeksCount, setWeeksCount] = useState(getLocalStorage(KEY_WEEKS_COUNT, 4));
  const [endDate, setEndDate] = useState(addDays(startDate, daysCount));
  const [beautyCalendarDaily, setBeautyCalendarDaily] = useState<any[]>([]);
  const [beautyCalendarMonthly, setBeautyCalendarMonthly] = useState<any[]>([]);
  const version = useVersion();
  const dataProvider = useDataProvider();

  const isXSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('xs')
  );
  const isSmall = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const createFakeData = (items, add) => {
    for (const item of items) {

    }
  }
  const fetchAffirmation = useCallback(async () => {
    const {data} = await dataProvider.getList<any>(
      'affirmation',
      {
        filter: {'date||$gte': getDateZeroTime(startDate), 'date||$lte': getDateZeroTime(endDate), published: true},
        sort: {field: 'date', order: 'ASC'},
        pagination: {page: 1, perPage: 300},
      }
    );
    console.log("DataAf", data);
    const all = addMissingDates({start: startDate, end: endDate, field: 'date', data})
    console.log("allAf", all);
    setAffirmation(all);
  }, [dataProvider, endDate]);
  const fetchSymbolOfDays = useCallback(async () => {

    const {data} = await dataProvider.getList<any>(
      'symbolOfDay',
      {
        filter: {'date||$gte': getDateZeroTime(startDate), 'date||$lte': getDateZeroTime(endDate), published: true},
        sort: {field: 'date', order: 'ASC'},
        pagination: {page: 1, perPage: 300},
      }
    );
    console.log("Data", data);
    const all = addMissingDates({start: startDate, end: endDate, field: 'date', data})
    console.log("all", all);
    setSymbolsOfDays(all);
  }, [dataProvider, endDate]);

  const fetchWisdom = useCallback(async () => {

    const {data} = await dataProvider.getList<any>(
      'wisdom',
      {
        filter: {'date||$gte': getDateZeroTime(startDate), 'date||$lte': getDateZeroTime(endDate), published: true},
        sort: {field: 'date', order: 'ASC'},
        pagination: {page: 1, perPage: 300},
      }
    );
    console.log("Data", data);
    const all = addMissingDates({start: startDate, end: endDate, field: 'date', data})
    console.log("all", all);
    setWisdom(all);
  }, [dataProvider, endDate]);

  const fetchHoroscopeDaily = useCallback(async () => {
    const {data} = await dataProvider.getList<any>(
      'horoscope',
      {
        filter: {'startDate||$gte': getDateZeroTime(startDate), 'startDate||$lte':  getDateZeroTime(endDate), type: 'daily'},
        sort: {field: 'startDate', order: 'ASC'},
        pagination: {page: 1, perPage: 300},
      }
    );
    console.log("DataH444", data);
    const grouped = groupByDatesWishSign(data);
    console.log("DataH1444Formatted", grouped);
    const all = generateSignForDates(addMissingDates({
      start: startDate,
      end: endDate,
      field: 'date',
      data: grouped,
      add: {items: []}
    }))
    console.log("allH1", all);
    setHoroscopeDaily(all);
  }, [dataProvider, endDate]);

  const fetchHoroscopeWeekly = useCallback(async () => {

    const {data} = await dataProvider.getList<any>(
      'horoscope',
      {
        filter: {'startDate||$gte': getDateZeroTime(getWeekStart(startDate)), 'startDate||$lte':  getDateZeroTime(addDays(getWeekStart(startDate),weeksCount * 7 )), type: 'weekly'},
        sort: {field: 'startDate', order: 'ASC'},
        pagination: {page: 1, perPage: 300},
      }
    );
    const grouped = groupByDatesWishSign(data);
    console.log("DataH1Formatted", grouped);
    const all = generateSignForDates(addMissingWeeks({
      start: startDate,
      weeksCount: weeksCount,
      field: 'date',
      data: grouped,
      add: {items: []}
    }))
    console.log("allHHH1", all);
    setHoroscopeWeekly(all);
  }, [dataProvider, weeksCount]);

  const fetchBeautyCalendarDaily = useCallback(async () => {

    const {data} = await dataProvider.getList<any>(
      'beautyCalendar',
      {

        filter: {'startDate||$gte': getDateZeroTime(startDate), 'startDate||$lte':  getDateZeroTime(endDate), type: 'daily', published: true},
        sort: {field: 'startDate', order: 'ASC'},
        pagination: {page: 1, perPage: 300},
      }
    );
    console.log("DataH1", data);
    const grouped = groupByDatesWishSign(data);
    console.log("DataH1Formatted", grouped);
    const all = generateSignForDates(addMissingDates({
      start: startDate,
      end: endDate,
      field: 'date',
      data: grouped,
      add: {items: []}
    }))
    console.log("allH1", all);
    setBeautyCalendarDaily(all);
  }, [dataProvider, endDate]);
  const fetchBeautyCalendarMonthly = useCallback(async () => {
    const current = new Date();
    const {data} = await dataProvider.getList<any>(
      'beautyCalendar',
      {
        filter: {'startDate||$gte': getDateZeroTime(getMonthStart(current)), 'startDate||$lte': getDateZeroTime(getMonthStart(addMonths(getMonthStart(current), monthsCount))), type: 'monthly'},
        sort: {field: 'startDate', order: 'ASC'},
        pagination: {page: 1, perPage: 300},
      }
    );
    const grouped = groupByDatesWishSign(data);
    console.log("Grouped", grouped);
    const all = generateSignForDates(addMissingMonths({
      start: startDate,
      monthsCount: monthsCount,
      field: 'date',
      data: grouped,
      add: {items: []}
    }))
    console.log("allBm", all);
    setBeautyCalendarMonthly(all);
  }, [dataProvider, monthsCount]);

  useEffect(() => {
    console.log("RefetchEndDate", endDate);
    fetchHoroscopeDaily();
    fetchBeautyCalendarDaily();
    fetchSymbolOfDays();
    fetchAffirmation();
    fetchWisdom()
  }, [version, endDate]);
  useEffect(() => {
    fetchBeautyCalendarMonthly();
  }, [version, monthsCount]);

  useEffect(() => {
    fetchHoroscopeWeekly();
  }, [version, weeksCount]);// eslint-disable-line react-hooks/exhaustive-deps
  console.log("symbolsOfDays", symbolsOfDays);

  const handleChangeDays = (val) => {
    console.log("handleChangeDays", val);
    setDaysCount(val);
    setLocalStorage(KEY_DAYS_COUNT, val);
    setEndDate(addDays(startDate, val));
  }
  const handleChangeMonths = (val) => {

    setMonthsCount(val);
    setLocalStorage(KEY_MONTHS_COUNT, val);
  }
  const handleChangeWeeks = (val) => {

    setWeeksCount(val);
    setLocalStorage(KEY_WEEKS_COUNT, val);
  }
  return (
    <>
      <Typography variant={'h4'}>Заполненность данных</Typography>
      <Box mt={2} display={'flex'}>
        <SelectBox
          label={'Кол-во дней'}
          helperText={'Для даннях по дням'}
          onChange={handleChangeDays}
          value={daysCount}
          options={[
            {value: 3, name: '3'},
            {value: 4, name: '4'},
            {value: 5, name: '5'},
            {value: 6, name: '6'},
            {value: 7, name: '7'},
            {value: 14, name: '14'},
            {value: 30, name: '30'}
          ]}/>

        <SelectBox
          label={'Кол-во недель'}
          onChange={handleChangeWeeks}

          helperText={'Для недельных гороскопов'}
          value={weeksCount}
          options={[
            {value: 3, name: '3'},
            {value: 4, name: '4'},
            {value: 5, name: '5'},
            {value: 6, name: '6'},
            {value: 7, name: '7'},
            {value: 8, name: '8'},
            {value: 9, name: '9'},
            {value: 10, name: '10'},
            {value: 11, name: '11'},
            {value: 12, name: '12'}
          ]}/>


        <SelectBox
          label={'Кол-во месяцев'}
          onChange={handleChangeMonths}
          value={monthsCount}

          helperText={'Для месячных календарей красоты'}
          options={[
            {value: 3, name: '3'},
            {value: 4, name: '4'},
            {value: 5, name: '5'},
            {value: 6, name: '6'},
            {value: 7, name: '7'},
            {value: 8, name: '8'},
            {value: 9, name: '9'},
            {value: 10, name: '10'},
            {value: 11, name: '11'},
            {value: 12, name: '12'}
          ]}/>
      </Box>
      <div style={styles.flex}>
        <div style={styles.leftCol}>
          <div style={styles.singleCol}>

            <SimpleDates
              title={'Символ дня'}
              data={symbolsOfDays}
              resource={'symbolOfDay'}
            />
          </div>
          <div style={styles.singleCol}>
            <SimpleDates
              title={'Мудрость дня'}
              data={wisdom}
              resource={'wisdom'}
            />
          </div>

        </div>
        <div style={styles.rightCol}>
          <div style={styles.singleCol}>
            <SimpleDates
              title={'Аффирмации'}
              data={affirmation}
              resource={'affirmation'}
            />
          </div>

        </div>
      </div>
      <div style={styles.flex}>
        <div style={styles.leftCol}>
          <div style={styles.singleCol}>
            <GroupedWithSign
              title={'Гороскопы (по дням)'}
              resource={'horoscope'}
              data={horoscopeDaily}
              rangeType={'day'}
            />
          </div>
          <div style={styles.singleCol}>
            <GroupedWithSign
              title={'Гороском (по неделям)'}
              resource={'horoscope'}
              data={horoscopeWeekly}
              rangeType={'week'}
            />

          </div>
        </div>
        <div style={styles.rightCol}>
          <div style={styles.singleCol}>
            <GroupedWithSign
              title={'Календарь красоты (по дням)'}
              resource={'beautyCalendar'}
              data={beautyCalendarDaily}
              rangeType={'day'}
            />
          </div>
          <div style={styles.singleCol}>
            <GroupedWithSign
              resource={'beautyCalendar'}
              title={'Календарь красоты (по месяцам)'}
              data={beautyCalendarMonthly}
              rangeType={'month'}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;