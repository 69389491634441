import PersonIcon from '@material-ui/icons/Person';
import NumbersInstructionList from './NumbersInstructionList'
import NumbersInstructionEdit from './NumbersInstructionEdit'
import NumbersInstructionCreate from './NumbersInstructionCreate'

export default {
  create: NumbersInstructionCreate,
  edit: NumbersInstructionEdit,
  list: NumbersInstructionList,
  icon: PersonIcon,
};