import { ReduxState } from 'ra-core'
export interface IRequestData {
  url: string
  method?: 'POST' | 'PUT' | 'DELETE' | 'GET'
  data?: any
  token?: string
  host?: string
}

export interface IResponse {
  data: any
  err: any
}

export interface BaseAction {
  type: string
  payload: any
}

export type ThemeName = 'light' | 'dark'

export interface AppState extends ReduxState {

}

export interface IUser {
  id: number
  name: string
}



export const SignTypes = [
  {id: 'Aries', name: 'Aries'},
  {id: 'Taurus', name: 'Taurus'},
  {id: 'Gemini', name: 'Gemini'},
  {id: 'Cancer', name: 'Cancer'},
  {id: 'Leo', name: 'Leo'},
  {id: 'Virgo', name: 'Virgo'},
  {id: 'Libra', name: 'Libra'},
  {id: 'Scorpio', name: 'Scorpio'},
  {id: 'Sagittarius', name: 'Sagittarius'},
  {id: 'Capricorn', name: 'Capricorn'},
  {id: 'Aquarius', name: 'Aquarius'},
  {id: 'Pisces', name: 'Pisces'},
]



export const LangTypes = [
  {id: 'ru', name: 'Русский'},
  {id: 'en', name: 'Английский'}
]

export const AvatarTypes = [
  {id: 'male', name: 'Мужчина'},
  {id: 'female', name: 'Женщина'}
]

export const GenderTypes = [
  {id: 'male', name: 'Мужчина'},
  {id: 'female', name: 'Женщина'}
]
export const PushTypes = [
  { id: 'admin', name: 'Общее уведомление' },
  { id: 'newAchievement', name: 'Новое достижение' },
  { id: 'wishCard', name: 'Карта желаний (Напоминание)' },
  { id: 'autoRemind', name: 'Авто уведомление' }
]
export const PushNotificationChannel = [
  { id: 'push', name: 'Push' },
  { id: 'alert', name: 'Уведомление' }
]
export const PushTargetTypes = [
  { id: 'all', name: 'Всем' },
  { id: 'user', name: 'Пользователю' }
]
export const PushStatuses = [
  { id: 'created', name: 'Черновик' },
  { id: 'toSent', name: 'Отправить' },
]
export const PushPriorities = [
  { id: 'high', name: 'Высокий' },
  { id: 'normal', name: 'Обычный' },
]
export const PushSoundTypes = [
  { id: '', name: 'Отключен' },
  { id: 'default', name: 'Включен' },
]
export const MonthList = [
  {id: 1, name: 'Январь'},
  {id: 2, name: 'Февраль'},
  {id: 3, name: 'Март'},
  {id: 4, name: 'Апрель'},
  {id: 5, name: 'Май'},
  {id: 6, name: 'Июнь'},
  {id: 7, name: 'Июль'},
  {id: 8, name: 'Август'},
  {id: 9, name: 'Сентябрь'},
  {id: 10, name: 'Октябрь'},
  {id: 11, name: 'Ноябрь'},
  {id: 12, name: 'Декабрь'},
]

export const PeriodList = [
  {id: 'daily', name: 'Дневной'},
  {id: 'weekly', name: 'Недельный'},
  {id: 'monthly', name: 'Месячный'}
]
export const TagTypeList = [
  {id: 'beautyCalendar', name: 'Календарь красоты'},
  {id: 'calendar', name: 'Календарь'},
]

export const CategoryTypeList = [
  {id: 'meditation', name: 'Медитация'},
  {id: 'coloring', name: 'Раскраски'},
]

export const PredictionTypeList = [
  {id: 'positive', name: 'Позитивный'},
  {id: 'semi-positive', name: 'Нерешительно положительные'},
  {id: 'neutral', name: 'Нейтральный'},
  {id: 'negative', name: 'Отрицательный'},
]


export const MoonPhaseList = [
  {id: 1, name: 'Новолуние (1)'},
  {id: 2, name: 'Молодая луна (2)'},
  {id: 3, name: 'Первая четверть (3)'},
  {id: 4, name: 'Прибывающая луна (4)'},
  {id: 5, name: 'Полнолуние (5)'},
  {id: 6, name: 'Убывающая луна (6)'},
  {id: 7, name: 'Последняя четверть (7)'},
  {id: 8, name: 'Старая луна (8)'},
  {id: 9, name: '9'},
  {id: 10, name: '10'},
  {id: 11, name: '11'},
  {id: 12, name: '12'},
]


export const NumberTypes = [
  {id: 'name', name: 'Число имени'},
  {id: 'birthday', name: 'Число рождения'},
  {id: 'mind', name: 'Число разума'},
  {id: 'expression', name: 'Число экспресии'},
  {id: 'lifePath', name: 'Число жизненного пути'},
  {id: 'motivation', name: 'Число душевного побуждения'},
  {id: 'personality', name: 'Число личности'},
]

export const TypedAssetTypes = [
  {id: 'melody', name: 'Мелодия'},
  {id: 'wishcardPicture', name: 'Картинка карты желаний'},
  {id: 'background', name: 'Фон'}
]


export const AchievementTypes = [
  {id: 'zen', name: 'Познать дзен'},
  {id: 'fortuneTeller', name: 'Гадалка'},
  {id: 'itHappens', name: 'Так бывает'},
  {id: 'starsTell', name: 'Звезды подскажут'},
  {id: 'numerologist', name: 'Нумеролог'},
  {id: 'beautyTime', name: 'Время для красоты'},
  {id: 'regularCustomer', name: 'Постоянный клиент'},
  {id: 'everyoneHere', name: 'Все в сборе'},
  {id: 'visualizeIt', name: 'Визуализируй это'},
  {id: 'shareWisdom', name: 'Поделиться мудростью'},
]

export const UserActionTypes = [
  {id: 'taroOpened', name: 'Открыто карта таро'},
  {id: 'taroPageVisited', name: 'Посещение экрана таро'},
  {id: 'predictionOpened', name: 'Открыто предсказание'},
  {id: 'predictionPageVisited', name: 'Посещение экрана предсказания'},
  {id: 'horoscopeOpened', name: 'Гороскоп выведен '},
  {id: 'horoscopePageVisited', name: 'Посещение экрана гороскопа'},
  {id: 'numberOpened', name: 'Вычислено число'},
  {id: 'beautyCalendarOpened', name: 'Календарь красоты получен'},
  {id: 'beautyCalendarPageVisited', name: 'Посещение экрана календарь красоты'},
  {id: 'appStarted', name: 'Приложение запущено'},
]


export const AdminRoles = [
  {id: 'admin', name: 'Администратор'},
  {id: 'manager', name: 'Контент менеджер'}
]
export const SignDescriptionValueTypeList = [
  {id: 'influence', name: 'Влияние'},
  {id: 'talisman', name: 'Талисман'},
  {id: 'symbol', name: 'Символ'},
  {id: 'luckyNumber', name: 'Благоприятные числа'},
  {id: 'color', name: 'Цвета'},
  {id: 'stone', name: 'Камень'},
  {id: 'metal', name: 'Металл'},
  {id: 'flower', name: 'Цветы'},
  {id: 'luckyDay', name: 'Счастливый день'},
  {id: 'unluckyDay', name: 'Неудачный день'}
]