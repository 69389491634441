import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {
  BooleanInput,
  Datagrid,
  DateField,
  SimpleForm,
  SimpleFormIterator,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  SelectInput,
  TextInput, useDataProvider, FormDataConsumer, ReferenceArrayInput, AutocompleteArrayInput, ArrayInput, DateInput,
} from 'react-admin';

import {MediaInput} from 'src/components/inputs/MediaInput'
import {RichTextInput} from 'src/components/inputs/RichTextInput'
import {AvatarTypes} from 'src/types'


export const AvatarForm = props => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <BooleanInput source={'published'} label={'Опубликовано'}/>
      <MediaInput source={'asset'} label={'Картинка'} validate={required()}/>
      <SelectInput source={'avatarType'} choices={AvatarTypes} label={'type'} variant={'outlined'}  validate={required()}/>
      <BooleanInput source={'defaultMaleAvatar'} label={'По умолчанию мужской'}/>
      <BooleanInput source={'defaultFemaleAvatar'} label={'По умолчанию женский'}/>

    </SimpleForm>
  );
};
