import React from 'react';
import {makeStyles} from '@material-ui/core/styles'
import { useTheme } from '@material-ui/core/styles';
const radius = 12;
const radius2x = Math.floor(2 * radius);

const useStyles = makeStyles(
  theme => ({
    root: {
      position: 'relative',
      display: 'inline-block',
      padding: `0px ${radius}px 0px ${radius}px`,
    },
    badge: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignContent: 'center',
      alignItems: 'center',
      position: 'relative',
      top: 0,
      right: 0,
      fontWeight: 500,
      fontSize: radius,
      minWidth: radius2x,
      height: radius2x,
      borderRadius: '50%',
    },
  }),
  { name: 'RaFileInput' },
)


const Badge = (props) => {
  const {
    style,
    children,
    badgeContent,
    badgeStyle,
    color,
    ...other
  } = props;
  const theme = useTheme();
  const classes = useStyles();
  const getBackgroundColor = () => {
    switch (color){
      case 'error':
        return theme.palette.error.main
      case 'success':
        return theme.palette.success.main
      case 'warning':
        return theme.palette.warning.main

    }
  }
  const getTextColor = () => {
    switch (color){
      case 'error':
        return '#fff'
      default:
        return theme.palette.text.primary
    }
  }

  return (
    <div {...other} className={classes.root}>
      <span className={classes.badge} style={{backgroundColor: getBackgroundColor(), color: getTextColor()}}>
          {badgeContent}
        </span>
    </div>
  );
  }
;

export default Badge;