import * as React from 'react';
import {FC, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {
  BooleanInput,
  Datagrid,
  Create,
  Pagination,
  ReferenceField,
  SimpleForm,
  DateField,
  Edit,
  EditButton,
  EditProps,
  FunctionField,
  FormTab,
  required,
  SelectInput,
  useDataProvider,
  DeleteButton,
  useRefresh,
  TabbedForm,
  TextField,
  ReferenceManyField,
  TextInput, SelectField, ReferenceInput, AutocompleteInput, Filter,
} from 'react-admin';
import {GenderTypes, SignTypes, UserActionTypes} from 'src/types'

import RelatedList from 'src/components/list/RelatedList'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'

interface TitleProps {
  record?: any;
}

export const UserActionList = (props) => {
  const {record} = props;
  return (
    <ReferenceManyField {...props} reference="user-action" target="userId" addLabel={false}
                        sort={{field: 'id', order: 'ASC'}} pagination={<Pagination/>} perPage={50}>
      <RelatedList resource="user-action" record={record} hasCustomCreate={false}
                   emptyTitle={'Нет действий'}>
        <IdShowButtonField source={'id'} label={'ID'}/>
        <SelectField source={'action'} label={'Действие'} choices={UserActionTypes}/>
        <DateField source="createdAt" label={'Создано'}showTime={true} />
       </RelatedList>
    </ReferenceManyField>)
}