import PersonIcon from '@material-ui/icons/Person';
import CompatibilityList from 'src/resources/compatibility/CompatibilityList'
import CompatibilityEdit from 'src/resources/compatibility/CompatibilityEdit'
import CompatibilityCreate from 'src/resources/compatibility/CompatibilityCreate'

export default {
  create: CompatibilityCreate,
  edit: CompatibilityEdit,
  list: CompatibilityList,
  icon: PersonIcon,
};