import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {
  BooleanInput,
  Datagrid,
  DateField,
  SimpleForm,
  SimpleFormIterator,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  SelectInput,
  TextInput,
  useDataProvider,
  FormDataConsumer,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ArrayInput,
  ReferenceInput,
  AutocompleteInput,
} from 'react-admin';

import {MediaInput} from 'src/components/inputs/MediaInput'
import {getTranslation} from 'src/utils/translation'
import {RichTextInput} from 'src/components/inputs/RichTextInput'

export const MeditationForm = props => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <BooleanInput source={'published'} label={'Опубликовано'}/>
      <MediaInput source={'preview'} label={'Картинка Превью'} helperText={'Выводить в списке медитаций'}/>
      <MediaInput source={'image'} label={'Картинка'}  helperText={'Выводить на экране медитации'}/>
      <MediaInput source={'sound'} label={'Музыка'} helperText={'Битрейт 128 kb/s'} accept={['audio/mpeg', 'audio/aac']} validate={required()}/>

      <ArrayInput source="translations" label={'Контент'}>
        <SimpleFormIterator>
          <SelectInput source="languageCode" label={'Язык'}  variant={'outlined'} choices={[{id: 'ru', name: 'RU'}, {id: 'en', name: 'EN'}]} validate={required()}/>
          <TextInput source="name" label={'Название'} multiline={true} variant={'outlined'} validate={required()}/>
          <RichTextInput source="body" label={'Описание'} multiline={true} fullWidth={true} variant={'outlined'} validate={required()} />
        </SimpleFormIterator>
      </ArrayInput>

    </SimpleForm>
  );
};
