import * as React from 'react';
import {FC} from 'react';
import {
  Filter,
  List,
  TextInput,
  ListProps,
  Datagrid,
  BooleanInput, DateField, SelectField, SelectInput, required, DateInput, SimpleForm
} from 'react-admin';
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import TransTextField from 'src/components/fields/TransTextField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import {getTranslation} from 'src/utils/translation'
import EmptyList from 'src/components/list/EmptyList'
import {PeriodList, SignTypes} from 'src/types'
import VisibilityField from 'src/components/fields/VisibilityField'


const exporter = posts => {
  return csvExporter({
    columns: {
      'id': 'ID',
      'firstSign': 'Знак зодиака первый',
      'secondSign': 'Знак зодиака второй',
      'createdAt': 'Создано',

    },
    data:  posts.map(item => ({
      ...item,
      firstSign: SignTypes.find(i => i.id === item.sign)?.name || '',
      secondSign: SignTypes.find(i => i.id === item.sign)?.name || '',
      createdAt:  formatCsvDate(item.createdAt),
      updatedAt:  formatCsvDate(item.updatedAt),
    })),
    fileName: 'Compatibility'
  })
};

const _Filter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'} />
     <BooleanInput source={'published'} label={'Опубликовано'}/>
    <SelectInput source={'firstSign'} label={'Знак первый'} choices={SignTypes} variant={'outlined'}  />
    <SelectInput source={'secondSign'} label={'Знак второй'} choices={SignTypes} variant={'outlined'}  />
  </Filter>
)

const CompatibilityList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      exporter={exporter}
      bulkActionButtons={false}
      filters={<_Filter/>}
      title="Совместимость"
      empty={<EmptyList title={'Нет Совместимости'} description={'Вы можете добавить Совместимость'} buttonText={'Добавить Совместимость'}/>}
    >
    <Datagrid rowClick={'edit'}>
      <IdShowButtonField source={'id'} label={'ID'}/>
      <VisibilityField source={'published'} label={'Опубликовано'}/>
      <SelectField source={'firstSign'} label={'Знак первый'} choices={SignTypes}/>
      <SelectField source={'secondSign'} label={'Знак второй'} choices={SignTypes}/>
      <DateField source="createdAt" label={'Создано'} showTime={true} />
      <DateField source="updatedAt" label={'Обновлено'} showTime={true} />
    </Datagrid>
    </List>
  );
}

export default CompatibilityList;
