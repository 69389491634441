import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {
  BooleanInput,
  Datagrid,
  DateField,
  SimpleForm,
  SimpleFormIterator,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  SelectInput,
  TextInput,
  useDataProvider,
  FormDataConsumer,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  ArrayInput,
  DateInput,
  SelectField,
} from 'react-admin';

import {MediaInput} from 'src/components/inputs/MediaInput'
import {RichTextInput} from 'src/components/inputs/RichTextInput'
import {AchievementTypes} from 'src/types'


export const AchievementForm = props => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <BooleanInput source={'published'} label={'Опубликовано'}/>
      <SelectInput source={'type'} label={'Тип'} choices={AchievementTypes} variant={'outlined'}/>
      <MediaInput source={'asset'} label={'Картинка'} />
      <ArrayInput source="translations" label={'Контент'}>
        <SimpleFormIterator>
          <SelectInput source="languageCode" label={'Язык'}  variant={'outlined'} choices={[{id: 'ru', name: 'RU'}, {id: 'en', name: 'EN'}]} validate={required()} />
          <TextInput source="title" label={'Название'} fullWidth={true} variant={'outlined'} validate={required()} />
          <TextInput source="body" label={'Описание'} multiline={true} fullWidth={true} variant={'outlined'} />
        </SimpleFormIterator>
      </ArrayInput>

    </SimpleForm>
  );
};
