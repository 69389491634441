import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {
  BooleanInput,
  Datagrid,
  DateField,
  SimpleForm,
  SimpleFormIterator,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  DateInput,
  SelectInput,
  TextInput, useDataProvider, FormDataConsumer, ReferenceArrayInput, AutocompleteArrayInput, ArrayInput,
} from 'react-admin';

import {MediaInput} from 'src/components/inputs/MediaInput'
import {PeriodList, SignTypes} from 'src/types'
import {RichTextInput} from 'src/components/inputs/RichTextInput'

export const HoroscopeForm = props => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <BooleanInput source={'published'} label={'Опубликовано'}/>
      <SelectInput source={'sign'} label={'Знак зодиака'} choices={SignTypes} variant={'outlined'} validate={required()} />
      <SelectInput source={'type'} label={'Период'} choices={[
        {id: 'daily', name: 'Дневной'},
        {id: 'weekly', name: 'Недельный'},
      ]} variant={'outlined'} validate={required()} />
      <DateInput source={'startDate'} label={'Стартовая дата'} variant={'outlined'} validate={required()}/>
      <ArrayInput source="translations" label={'Контент'}>
        <SimpleFormIterator>
          <SelectInput source="languageCode" label={'Язык'}  variant={'outlined'} choices={[{id: 'ru', name: 'RU'}, {id: 'en', name: 'EN'}]} validate={required()} />
          <TextInput source="title" label={'Заголовок'} multiline={true}  fullWidth={true} variant={'outlined'} validate={required()} />
          <RichTextInput source="body" label={'Описание'} multiline={true} fullWidth={true} variant={'outlined'} validate={required()} />
          <ArrayInput source="blocks" label={'Текст блоки'}>
            <SimpleFormIterator>
              <TextInput source="title" label={'Заголовок Текст блока'} multiline={true} fullWidth={true} variant={'outlined'} validate={required()} />
              <RichTextInput source="body" label={'Описание Текст блока'} multiline={true} fullWidth={true} variant={'outlined'} validate={required()} />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleFormIterator>
      </ArrayInput>

    </SimpleForm>
  );
};
