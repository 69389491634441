import React, {useState} from 'react';
import {
  BooleanInput,
  required,
  TextInput, useDataProvider, useRefresh,
  ArrayInput, SimpleFormIterator, ReferenceInput, AutocompleteInput, SelectInput
} from "react-admin";

import ModalForm from 'src/components/ModalForm'
import {getTranslation} from 'src/utils/translation'

export const UserAchievementForm = (props) => {
  const { record} = props;
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const handleSubmit = async data => {
    await dataProvider.create('user-achievement', {data: {
       ...data,
        userId: record.id
      }});
    refresh();
    return true;
  };

  return <ModalForm
    {...props}
    title={'Добавить достижение пользователю'}
    handleClose={props.onClose}
    save={handleSubmit}
    fullWidth={true}
    record={record}
  >
    <ReferenceInput label="Достижение" source="achievementId" reference="achievement" variant={'outlined'}
                    filterToQuery={searchText => ({ ...(searchText ? { 'name': searchText} : {}) })}
                    sort={{ field: 'id', order: 'ASC' }}
                    allowNull
                    format={(v) => (!v ? null : v)}
                    fullWidth={true} perPage={100}
      validate={required()}
    >
      <SelectInput optionText={(record) => `${getTranslation(record)?.title}`}/>
    </ReferenceInput>
  </ModalForm>
}
