import PersonIcon from '@material-ui/icons/Person';
import AchievementList from './AchievementList'
import AchievementEdit from './AchievementEdit'
import AchievementCreate from './AchievementCreate'

export default {
  create: AchievementCreate,
  edit: AchievementEdit,
  list: AchievementList,
  icon: PersonIcon,
};