import * as React from 'react';
import {FC, useEffect, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {
  BooleanInput,
  Datagrid,
  DateField,
  SimpleForm,
  SimpleFormIterator,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  DateInput,
  SelectInput,
  TextInput, useDataProvider, FormDataConsumer, ReferenceArrayInput, AutocompleteArrayInput, ArrayInput,
} from 'react-admin';

import {MediaInput} from 'src/components/inputs/MediaInput'
import {PeriodList, SignTypes} from 'src/types'
import {RichTextInput} from 'src/components/inputs/RichTextInput'

export const CompatibilityForm = props => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <BooleanInput source={'published'} label={'Опубликовано'}/>
      <SelectInput source={'firstSign'} label={'Знак зодиака первый'}  helperText={'Слева'}  choices={SignTypes} variant={'outlined'} validate={required()} />
      <SelectInput source={'secondSign'} label={'Знак зодиака второй'}  helperText={'Справа'}  choices={SignTypes} variant={'outlined'} validate={required()} />
      <ArrayInput source="translations" label={'Контент'}>
        <SimpleFormIterator>
          <SelectInput source="languageCode" label={'Язык'}  variant={'outlined'} choices={[{id: 'ru', name: 'RU'}, {id: 'en', name: 'EN'}]} validate={required()} />
          <ArrayInput source="compatibilityBlocks" label={'Совместимости'}>
            <SimpleFormIterator>
              <TextInput source="firstTitle" label={'Заголовок первый'} helperText={'Слева'} fullWidth={true} variant={'outlined'} validate={required()} />
              <TextInput source="secondTitle" label={'Описание второй'}  helperText={'Справа'} fullWidth={true} variant={'outlined'} validate={required()} />
              <TextInput source="body" label={'Описание'} multiline={true} fullWidth={true} variant={'outlined'} validate={required()} />
              <BooleanInput source="compatibility" label={'Есть Совместимость'} helperText={'Если есть то иконка сердца будет цветная, если нет то серая'} multiline={true} fullWidth={true} variant={'outlined'} />
            </SimpleFormIterator>
          </ArrayInput>

          <ArrayInput source="blocks" label={'Текст блоки'}>
            <SimpleFormIterator>
              <TextInput source="title" label={'Заголовок Текст блока'} multiline={true} fullWidth={true} variant={'outlined'} validate={required()} />
              <RichTextInput source="body" label={'Описание Текст блока'} multiline={true} fullWidth={true} variant={'outlined'} validate={required()} />
            </SimpleFormIterator>
          </ArrayInput>
        </SimpleFormIterator>
      </ArrayInput>

    </SimpleForm>
  );
};
