import React, {useEffect} from 'react'
import {Admin, Resource, DataProvider} from 'react-admin'

import LoginPage from "./resources/login";
import {AuthProvider} from "src/common/providers/AuthProvider";
import {dataProvider} from "src/common/providers/dataProvider";
// @ts-ignore

import russianMessages from 'ra-language-russian'
import polyglotI18nProvider from 'ra-i18n-polyglot'

import {theme} from 'src/style'
import administrators from 'src/resources/administrators'
import affirmation from 'src/resources/affirmation'
import wisdom from 'src/resources/wisdom'
import symbolOfDay from 'src/resources/symbol-of-day'
import avatar from 'src/resources/avatar'
import prediction from 'src/resources/prediction'
import meditation from 'src/resources/meditation'
import horoscope from 'src/resources/horoscope'
import number from 'src/resources/number'
import numberInstruction from 'src/resources/numbers-instruction'
import compatibility from 'src/resources/compatibility'
import compatibilityInstruction from 'src/resources/compatibility-instruction'
import signDescription from 'src/resources/sign-description'
import typedAsset from 'src/resources/typed-asset'
import achievement from 'src/resources/achievement'
import beautyCalendar from 'src/resources/beauty-calendar'
import push from 'src/resources/push'
import user from 'src/resources/user'
import userAction from 'src/resources/user-action'
import taro from 'src/resources/taro'
import page from 'src/resources/page'
import faq from 'src/resources/faq'
import settings from 'src/resources/settings'
import media from 'src/resources/media'
import {Dashboard} from 'src/resources/dashboard'

const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru')

const App = () => {
  return (
    <Admin
      theme={theme}
      i18nProvider={i18nProvider}
      dataProvider={dataProvider}
      authProvider={AuthProvider}
      loginPage={LoginPage}
      dashboard={Dashboard}
    >

      {permissions => {
        return [
          <Resource name="avatar" {...avatar} options={{label: 'Аватары'}}/>,
          <Resource name="affirmation" {...affirmation} options={{label: 'Аффирмации'}}/>,
          <Resource name="wisdom" {...wisdom} options={{label: 'Мудрость дня'}}/>,
          <Resource name="symbolOfDay" {...symbolOfDay} options={{label: 'Символ дня'}}/>,
          <Resource name="meditation" {...meditation} options={{label: 'Медитации'}}/>,

          <Resource name="horoscope" {...horoscope} options={{label: 'Гороскопы'}}/>,
          <Resource name="beautyCalendar" {...beautyCalendar} options={{label: 'Календарь красоты'}}/>,

          <Resource name="numbers" {...number} options={{label: 'Числа нумеорологии'}}/>,
          <Resource name="numbersInstruction" {...numberInstruction} options={{label: 'Описания нумерологии'}}/>,

          <Resource name="prediction" {...prediction} options={{label: 'Шар предсказаний'}}/>,
          <Resource name="taro" {...taro} options={{label: 'Карты таро'}}/>,

          <Resource name="compatibility" {...compatibility} options={{label: 'Совместимость'}}/>,
          <Resource name="compatibilityInstruction" {...compatibilityInstruction} options={{label: 'Описания совместимости'}}/>,
          <Resource name="signDescription" {...signDescription} options={{label: 'О знаках'}}/>,

          <Resource name="achievement" {...achievement} options={{label: 'Достижения'}}/>,

          <Resource name="typedAsset" {...typedAsset} options={{label: 'Медиа'}}/>,
          <Resource name="faq" {...faq} options={{label: 'Вопросы и ответы'}}/>,
          <Resource name="page" {...page} options={{label: 'Текстовые страницы'}}/>,

          <Resource name="notification" {...push} options={{label: 'Уведомления'}}/>,
          <Resource name="asset" {...media} options={{label: 'Файлы'}}/>,
          ...(permissions === 'admin' ? [
            <Resource name="user" {...user} options={{label: 'Пользователи'}}/>,
            <Resource name="user-action" {...userAction} options={{label: 'Действия пользователей'}}/>,
            <Resource name="admin" {...administrators} options={{label: 'Админы'}}/>,
            <Resource name="settings" {...settings} options={{label: 'Настройки'}}/>,
            <Resource name="user-achievement"/>,
            <Resource name="profile"/>,

          ] : [])
        ];
      }
        }
        </Admin>
        )
      }

export default App
