import * as React from 'react';
import {FC} from 'react';
import {
  Filter,
  List,
  TextInput,
  ListProps,
  Datagrid,
  SelectField,
  TextField,
  BooleanInput, DateField, SimpleForm, SelectInput, required, NumberInput
} from 'react-admin';
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import TransTextField from 'src/components/fields/TransTextField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import {getTranslation} from 'src/utils/translation'
import EmptyList from 'src/components/list/EmptyList'
import VisibilityField from 'src/components/fields/VisibilityField'
import {PredictionTypeList} from 'src/types'


const exporter = posts => {
  return csvExporter({
    columns: {
      'id': 'ID',
      'type': 'Ответ',
      'weight': 'Вес',
      'answer': 'Ответ',
      'createdAt': 'Создано',

    },
    data:  posts.map(item => ({
      ...item,
      type: PredictionTypeList.find(i => i.id === item.type)?.name,
      answer: getTranslation(item)?.answer,
      published: item.published ? 'Да' : '',
      createdAt:  formatCsvDate(item.createdAt),
      updatedAt:  formatCsvDate(item.updatedAt)
    })),
    fileName: 'Prediction'
  })
};

const _Filter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'} />
    <TextInput source="translations.answer||$contL" label={'Вопрос'}  variant={'outlined'}  alwaysOn={true} resettable={true}/>
    <SelectInput type={'type'} choices={PredictionTypeList} label={'Тип'} variant={'outlined'}  alwaysOn={true} resettable={true}/>
    <NumberInput source={'weight'} label={'Вес'}   variant={'outlined'}  />
    <BooleanInput source={'published'} label={'Опубликовано'}/>

  </Filter>
)

const PredictionList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      exporter={exporter}
      bulkActionButtons={false}
      filters={<_Filter/>}
      title="Ответ Шар предсказаний"
      empty={<EmptyList title={'Нет ответов шара предсказаний'} description={'Вы можете добавить ответ шара предсказаний'} buttonText={'Добавить ответ шара предсказаний'}/>}
    >
    <Datagrid rowClick={'edit'}>
      <IdShowButtonField source={'id'} label={'ID'}/>
      <VisibilityField source={'published'} label={'Опубликовано'}/>
      <SelectField source="type" label={'Тип'} choices={PredictionTypeList}/>
      <TextField source="weight" label={'Вес'}/>
      <TransTextField source="answer" label={'Ответ'}/>
      <DateField source="createdAt" label={'Создано'} showTime={true} />
      <DateField source="updatedAt" label={'Обновлено'} showTime={true} />
    </Datagrid>
    </List>
  );
}

export default PredictionList;
