import React, {useState} from 'react';
import {
  BooleanInput,
  required,
  DateInput,
  TextInput, useDataProvider, useRefresh,
  ArrayInput, SimpleFormIterator, ReferenceInput, AutocompleteInput, SelectInput, Filter
} from "react-admin";

import ModalForm from 'src/components/ModalForm'
import {getTranslation} from 'src/utils/translation'
import {GenderTypes, SignTypes} from 'src/types'


export const UserProfileEditForm = (props) => {
  const { record } = props;
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const handleSubmit = async data => {
    console.log("Update Profile", data);
    await dataProvider.update('profile', {id: record.id, data, previousData: record});
    refresh();
    return true;
  };

  return <ModalForm
    {...props}
    title={'Изменить профиль'}
    handleClose={props.onClose}
    save={handleSubmit}
    record={props.record}

  >
    <TextInput source={'name'} label={'Имя'} variant={'outlined'} alidate={required()}/>
    <SelectInput source={'gender'} label={'Пол'} choices={GenderTypes} variant={'outlined'} alidate={required()} />
    <DateInput source={'birthday'} label={'Дата Рождения'} variant={'outlined'}  validate={required()}/>
    <SelectInput source={'sign'} label={'Знак зодиака'} choices={SignTypes} variant={'outlined'} validate={required()} />

  </ModalForm>
}

export const UserProfileCreateForm = (props) => {
  const { record} = props;
  const refresh = useRefresh();
  const dataProvider = useDataProvider();

  const handleSubmit = async data => {
    await dataProvider.create('profile', {data: {
       ...data,
        userId: record.id
      }});
    refresh();
    return true;
  };

  return <ModalForm
    {...props}
    title={'Добавить профиль'}
    handleClose={props.onClose}
    save={handleSubmit}
    fullWidth={true}
    record={record}
  >
    <TextInput source={'name'} label={'Имя'} variant={'outlined'} alidate={required()}/>
    <SelectInput source={'gender'} label={'Пол'} choices={GenderTypes} variant={'outlined'} alidate={required()} />
    <DateInput source={'birthday'} label={'Дата Рождения'} variant={'outlined'}  validate={required()}/>
    <SelectInput source={'sign'} label={'Знак зодиака'} choices={SignTypes} variant={'outlined'} validate={required()} />

  </ModalForm>
}
