import PersonIcon from '@material-ui/icons/Person';
import NumberList from './NumberList'
import NumberEdit from './NumberEdit'
import NumberCreate from './NumberCreate'

export default {
  create: NumberCreate,
  edit: NumberEdit,
  list: NumberList,
  icon: PersonIcon,
};