import * as React from 'react';
import {FC} from 'react';
import {
  Filter,
  List,
  TextInput,
  TextField,
  ListProps,
  Datagrid,
  BooleanInput, DateField, SimpleForm, SelectInput, SelectField, Tab
} from 'react-admin';
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import TransTextField from 'src/components/fields/TransTextField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import {getTranslation} from 'src/utils/translation'
import EmptyList from 'src/components/list/EmptyList'
import VisibilityField from 'src/components/fields/VisibilityField'
import {DateInput} from 'src/components/inputs/DateInput'
import {GenderTypes, LangTypes, PeriodList, SignTypes} from 'src/types'
import LinkField from 'src/components/fields/LinkField'


const exporter = posts => {
  return csvExporter({
    columns: {
      'id': 'ID',
      'phone': 'Телефон',
      'language': 'Язык',
      'name': 'Имя',
      'gender': 'Пол',
      'sign': 'Знак зодиака',
      'birthday': 'Дата рождения',
      'totalTaroOpened': 'Открыто карт таро',
      'totalTaroPageVisits': 'Посещение экрана таро',
      'totalPredictions': 'Всего предсказаний',
      'totalPredictionsPageVisits': 'Посещение страницы предсказаний',
      'totalHoroscopes': 'Кол-во гороскопов по API',
      'totalHoroscopePageVisits': 'Посещение экрана гороскопы',
      'totalNumbers': 'Вычислено чисел',
      'totalBeautyCalendar': 'Кол-во календарей красоты по API',
      'totalBeautyCalendarPageVisits': 'Посещение календарь красоты',
      'totalAppStarted': 'Кол-во открытий приложения',
      'daysOfUseInRow': 'Использовано дней подряд',
      'maxDaysOfUseInRow': 'Использовано макс кол-во дней подряд',
      'createdAt': 'Создано',

    },
    data:  posts.map(item => ({
      ...item,
      name: item.defaultProfile?.name,
      sign: SignTypes.find( i => i.id === item.defaultProfile?.sign)?.name,
      language: LangTypes.find( i => i.id === item.lang)?.name,
      gender: GenderTypes.find(i => i.id === item.defaultProfile?.gender)?.name,
      birthday: formatCsvDate(item.defaultProfile?.birthday),
      createdAt:  formatCsvDate(item.createdAt),
      updatedAt:  formatCsvDate(item.updatedAt)
    })),
    fileName: 'User'
  })
};

const _Filter = (props) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'} />
    <TextInput source="phone||$contL" label={'Телефон'}  variant={'outlined'} alwaysOn={true} resettable={true} />
    <TextInput source="defaultProfile.name||$contL" label={'Имя'}  variant={'outlined'} />
    <SelectInput source={'defaultProfile.sign'} label={'Знак зодиака'} choices={SignTypes} variant={'outlined'}  />
    <SelectInput source={'defaultProfile.gender'} label={'Пол'} choices={GenderTypes} variant={'outlined'}  />
    <SelectInput source={'language'} label={'Язык'} choices={LangTypes} variant={'outlined'}/>
  </Filter>
)

const UserList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      exporter={exporter}
      bulkActionButtons={false}
      filters={<_Filter/>}
      title="Пользователи"
      empty={<EmptyList title={'Нет Пользователей'} />}
    >
    <Datagrid >
      <IdShowButtonField source={'id'} label={'ID'}/>
      <TextField source={'phone'} label={'Телефон'}/>
      <TextField source={'defaultProfile.name'} label={'Имя'}/>
      <SelectField source={'defaultProfile.sign'} choices={SignTypes} label={'Знак зодиака'}/>
      <SelectField source={'defaultProfile.gender'} choices={GenderTypes} label={'Пол'}/>
      <DateField source="defaultProfile.birthday" label={'Дата рождения'} />
      <SelectField source={'language'} label={'Язык'} choices={LangTypes}/>
      <DateField source="createdAt" label={'Создано'}showTime={true} />
      <LinkField value={'Действия'} label={'Действия'} link={'/user-action??displayedFilters={"userId":true}&filter={"userId": $id}'}/>

      <DateField source={'lastActivityAt'} label={'Был онлайн'} showTime={true}/>
      <TextField source={'totalTaroOpened'} label={'Открыто карт таро'}/>
      <TextField source={'totalTaroPageVisits'} label={'Посещение экрана таро'}/>
      <TextField source={'totalPredictions'} label={'Всего предсказаний'}/>
      <TextField source={'totalPredictionsPageVisits'} label={'Посещение страницы предсказаний'}/>
      <TextField source={'totalHoroscopes'} label={'Кол-во гороскопов по API'}/>
      <TextField source={'totalHoroscopePageVisits'} label={'Посещение экрана гороскопы'}/>
      <TextField source={'totalNumbers'} label={'Вычислено чисел'}/>
      <TextField source={'totalBeautyCalendar'} label={'Кол-во календарей красоты по API'}/>
      <TextField source={'totalBeautyCalendarPageVisits'} label={'Посещение календарь красоты'}/>
      <TextField source={'totalAppStarted'} label={'Кол-во открытий приложения'}/>
      <TextField source={'daysOfUseInRow'} label={'Использовано дней подряд'}/>
      <TextField source={'maxDaysOfUseInRow'} label={'Использовано макс кол-во дней подряд'}/>
      <DateField source="updatedAt" label={'Обновлено'} showTime={true} />

    </Datagrid>
    </List>
  );
}

export default UserList;
