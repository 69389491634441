import {addDays, endOfWeek, isSameDay, startOfWeek} from 'date-fns'
import {


  getMonthStart,
  getNextMonthStart,


} from '@wojtekmaj/date-utils';

const getNextWeekStart = (date) => {
  return startOfWeek(new Date(date.getTime()  + 7 * 24 * 60 * 60 * 1000), {weekStartsOn: 1});
}
export const getWeekStart = (date) => {
  return startOfWeek(new Date(date), {weekStartsOn: 1});
}
export const getWeekEnd = (date) => {
  return endOfWeek(new Date(date), {weekStartsOn: 1});
}
function datesArray(start, end) {
  let result = [], current = new Date(start);
  while (current <= end)
    result.push(current) && (current = new Date(current)) && current.setDate(current.getDate() + 1);
  return result;
}
function monthsArray(start, monthsCount) {
  const result = [];
  let curMonth = start;
  result.push(curMonth)
  for(let i = 0; i < monthsCount; ++i){
    curMonth = getNextMonthStart(curMonth);
    result.push(curMonth)
  }
  return result;
}
function weeksArray(start, monthsCount) {
  const result = [];
  let curWeek = start;
  result.push(curWeek)
  for(let i = 0; i < monthsCount; ++i){
    curWeek = getNextWeekStart(curWeek);
    result.push(curWeek)
  }
  return result;
}
interface Props{
  field?: string,
  data: any[],
  add?: any
}
interface PropsDates{
  start: Date,
  end: Date,
}
interface PropsMonths{
  start: Date,
  monthsCount: number,
}
interface PropsWeeks{
  start: Date,
  weeksCount: number,
}
export const addMissingDates = ({start, end, data, field = 'date', add = {}}: PropsDates & Props) => {
  const allDates = datesArray(start, end);
  const complete = [];
  for(const curDate of allDates){
    const find = data.find( i => isSameDay(new Date(i[field]), curDate));
    if(find){
      complete.push(find)
    }else{
      complete.push({[field]: curDate.toString(), ...add})
    }
  }
  return complete;
}

export const addMissingMonths = ({start, monthsCount, data, field = 'date', add = {}}: PropsMonths & Props) => {
  const allDates = monthsArray(getMonthStart(start), monthsCount);
  const complete = [];
  console.log("AllMonths", allDates);

  for(const curDate of allDates){
    const find = data.find( i => isSameDay(getMonthStart(new Date(i[field])), curDate));
    if(find){
      complete.push(find)
    }else{
      complete.push({[field]: curDate.toString(), ...add})
    }
  }
  return complete;
}


export const addMissingWeeks = ({start, weeksCount, data, field = 'date', add = {}}: PropsWeeks & Props) => {
  const allDates = weeksArray(getWeekStart(start), weeksCount);
  const complete = [];
  console.log("AllMonths", allDates);

  for(const curDate of allDates){
    const find = data.find( i => isSameDay(getWeekStart(new Date(i[field])), curDate));
    if(find){
      complete.push(find)
    }else{
      complete.push({[field]: curDate.toString(), ...add})
    }
  }
  return complete;
}