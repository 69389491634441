import * as React from 'react';
import {FC, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {
  BooleanInput,
  Datagrid,
  Create,
  Pagination,
  ReferenceField,
  SimpleForm,
  DateField,
  Edit,
  EditButton,
  EditProps,
  FormTab,
  required,
  SelectInput,
  useDataProvider,
  DeleteButton,
  useRefresh,
  TabbedForm,
  TextField,
  ReferenceManyField,
  TextInput, SelectField,
} from 'react-admin';
import {GenderTypes, SignTypes} from 'src/types'

import RelatedList from 'src/components/list/RelatedList'
import {ModalCreateButton} from 'src/components/Modal/ModalButton'
import {UserAchievementForm} from 'src/resources/user/UserAchievementForm'
import TransTextField from 'src/components/fields/TransTextField'

interface TitleProps {
  record?: any;
}

export const UserAchievementList = (props) => {
  const {record} = props;
  return (
    <ReferenceManyField {...props} reference="user-achievement" target="userId" addLabel={false}
                        sort={{field: 'id', order: 'ASC'}} pagination={<Pagination/>} perPage={50}>
      <RelatedList resource="user-achievement" record={record}
                   actions={<ModalCreateButton modal={<UserAchievementForm/>}/>}
                   emptyTitle={'Нет достижений'}
                   createButton={<ModalCreateButton modal={<UserAchievementForm/>}/>}>
        <ReferenceField label="Достижение" source="achievementId" reference="achievement">
          <TransTextField source="title"/>
        </ReferenceField>
        <DateField source={'createdAt'} label={'Получено'} showTime={true}/>

        <DeleteButton mutationMode="pessimistic" redirect={false}/>
      </RelatedList>
    </ReferenceManyField>)
}