import * as React from 'react';
import {FC, useState} from 'react';
import keyBy from 'lodash/keyBy'
import {
  BooleanInput,
  Datagrid,
  Create,
  Pagination,
  ReferenceField,
  SimpleForm,
  DateField,
  Edit,
  EditButton,
  EditProps,
  FunctionField,
  FormTab,
  required,
  SelectInput,
  useDataProvider,
  DeleteButton,
  useRefresh,
  TabbedForm,
  TextField,
  ReferenceManyField,
  TextInput, SelectField,
} from 'react-admin';
import {GenderTypes, SignTypes} from 'src/types'

import RelatedList from 'src/components/list/RelatedList'
import {ModalCreateButton, ModalEditButton} from 'src/components/Modal/ModalButton'
import {UserProfileEditForm, UserProfileCreateForm} from 'src/resources/user/UserProfileForm'
import TransTextField from 'src/components/fields/TransTextField'

interface TitleProps {
  record?: any;
}

export const UserProfileList = (props) => {
  const {record} = props;
  return (
    <ReferenceManyField {...props} reference="profile" target="userId" addLabel={false}
                        sort={{field: 'id', order: 'ASC'}} pagination={<Pagination/>} perPage={50}>
      <RelatedList resource="profile" record={record}
                   actions={<ModalCreateButton modal={<UserProfileCreateForm/>}/>}
                   emptyTitle={'Нет профилей'}
                   createButton={<ModalCreateButton modal={<UserProfileCreateForm/>}/>}>
        <TextField source={'name'} label={'Имя'}/>
        <SelectField source={'sign'} choices={SignTypes} label={'Знак зодиака'}/>
        <SelectField source={'gender'} choices={GenderTypes} label={'Пол'}/>
        <DateField source="birthday" label={'Дата рождения'} />
        <FunctionField render={i => record?.defaultProfile?.id === i.id ? 'Главный профиль' : 'Доп профиль'}/>
        <DateField source={'createdAt'} label={'Создан'} showTime={true}/>
        <ModalEditButton modal={<UserProfileEditForm/>}/>
      </RelatedList>
    </ReferenceManyField>)
}